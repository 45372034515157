<template>
    <b-card class="mp-0">

       <!-- Tabs -->
      <b-tabs lazy v-model="tabIndex">
        <b-tab title="Set rates">
          <div class="alert-body"><Role /> </div>
        </b-tab>

<!--         <b-tab title="User Management">-->
<!--          <div class="alert-body"><User /> </div>-->
<!--        </b-tab>-->

        <b-tab title="User Management">
          <div class="alert-body"> <PcnAdmin/> </div>
        </b-tab>

        <b-tab title="Add Roles" lazy>
          <div class="alert-body"><AddRole /> </div>
        </b-tab>

        <b-tab title="Rota Management">
          <div class="alert-body"> <Rota /></div>
        </b-tab>


        <b-tab title="Shift Templates">
          <div class="alert-body"> <shift_templates/></div>
        </b-tab>

        <b-tab title="Services">
          <div class="alert-body"> <Service /></div>
        </b-tab>

        <b-tab title="Timesheets">
          <div class="alert-body"> <Timesheet /></div>
        </b-tab>

        <b-tab title="Admin access invitations">
          <div class="alert-body"> <users-invitations /></div>
        </b-tab>
        <b-button
            size="23"
            variant="outline-white"
            class="btn-icon bg-white shadow-sm text-primary absolute mr-2" >

          <feather-icon
              size="18"
              icon="DownloadIcon"
          />
        </b-button>
      </b-tabs>
   </b-card>

</template>

<script>
import Role from './role'
import Rota from './rota'
import User from './user'
import Timesheet from './timesheet'
import Service from './service'
import Shift_templates from './shift_templates'
import AddRole from './addRole.vue'


import { BCard, BTabs , BTab, BButton, BRow, BCol} from 'bootstrap-vue'
import UsersInvitations from "@/views/settings/users-invitations";
import PcnAdmin from "@/views/settings/pcnAdmins";

export default {
  name: 'Home',
  components: {
    Shift_templates,
    AddRole,
    PcnAdmin,
    UsersInvitations,
    Role,
    Rota,
    User,
    Service,
    Timesheet,
    BCard,
    BButton,
    BTabs,
    BTab,
    BRow,
    BCol
  },

  data(){
    return{
      tabIndex:this.$route.query.active_tab,
    }
  }
}
</script>

<style>
.absolute {
  position: absolute;
  top: 20px;
  right: 0;
}
</style>
