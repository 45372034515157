<template>
  <div>
    <!--    <router-view></router-view>-->
    <b-card class="mb-0" no-body>
      <div class="">
        <b-button
            class=" float-right mb-1 button-size"
            size="sm"
            variant="primary"
            @click="toggleSideBars()"
        >
          <span class="align-middle" >+ Create shift template</span>
        </b-button>
      </div>
      <div>
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
          <b-row>

            <b-col>
              <b-table
                  :current-page="currentPage"
                  :per-page="pagination.perPage"
                  :fields="fields"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :items="getService"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"

                  hover
                  ref="table"

                  class="mobile_table_css"
                  responsive
              >

                <template #cell(action)="data">
                  <div>
                    <b-button
                        size="23"
                        variant="outline-white"
                        class="btn-icon shadow-sm bg-white text-primary"
                        v-b-tooltip.hover.top="'Edit'"
                        @click="editShifts(data.item)">

                      <feather-icon
                          size="18"
                          icon="EditIcon"
                      />
                    </b-button>

                    <b-button
                        size="23"
                        variant="outline-white"
                        class="btn-icon shadow-sm bg-white text-danger ml-1"
                        @click="removeService(data.item.id)"
                        v-b-tooltip.hover.top="'Delete'">

                      <feather-icon
                          size="18"
                          icon="TrashIcon" />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!--                pagination-->
          <div class="mx-1 mb-2">
            <b-row>
              <b-col cols="12" v-if="noDataTable === 0" class="text-center">
                <span >No data for preview</span>
              </b-col>

              <!-- Pagination -->

              <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
                <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
              </b-col>

              <!-- Pagination -->

              <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

                <b-pagination
                    v-model="currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    class="mb-0 mt-1 mt-sm-0 "
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item">

                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>

                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>

                </b-pagination>

              </b-col>


            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-card>


    <AddShiftTemplatesSideBar
        ref="AddLocationSideBar"
        :isAddSiftTemplatesSidebarActive="isAddSiftTemplatesSidebarActive"
        @toggleSideBars="toggleSideBars()"
        @dataRefresh="dataRefresh()"
    />

    <EditShiftTemplatesSideBar
        ref="EditLocationSideBar"
        :selected-data = "selectedData"
        :isEditSiftTemplatesSidebarActive="isEditSiftTemplatesSidebarActive"
        @editShifts() ="editShifts()"
        @dataRefresh="dataRefresh()"
    />


  </div>
</template>

<script>


import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia, BOverlay,
  BPagination,
  BRow,
  BTable,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import AddShiftTemplatesSideBar from '@/views/settings/sidebars/addShiftTemplatesSideBar'
import EditShiftTemplatesSideBar from '@/views/settings/sidebars/editShiftTemplatesSideBar'
import settings from '@/apis/modules/settings'
import Ripple from 'vue-ripple-directive'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'

export default {
  name: 'services',
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  components: {
    AddShiftTemplatesSideBar,
    EditShiftTemplatesSideBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    vSelect,
    FeatherIcon,
    BOverlay
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data: function () {
    return {
      selectedData: {},
      isAddSiftTemplatesSidebarActive: false,
      isEditSiftTemplatesSidebarActive: false,
      tableLoading:false,
      noDataTable:'',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'start_time',
          label: 'Start Time'
        },
        {
          key: 'end_time',
          label: 'End Time'
        },
        {
          key: 'break_minute',
          label: 'Break',
        },
        {
          key: 'service',
          label: 'Activity'
        },
        {
          key:'action',
          label:'',
        }
      ],
      items: []
    }
  },
  methods: {
    dataRefresh(){
      this.$refs.table.refresh()
      this.isAddSiftTemplatesSidebarActive = false
      this.isEditSiftTemplatesSidebarActive = false

    },
    toggleSideBars() {
      this.isAddSiftTemplatesSidebarActive = !this.isAddSiftTemplatesSidebarActive
    },
    editShifts(item){
      this.selectedData = item;
      this.isEditSiftTemplatesSidebarActive = !this.isEditSiftTemplatesSidebarActive
    },
    async getService(){
      try {
        this.tableLoading = true
        const Response= await settings.getShiftTemplate(this.currentPage,10)
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          service: x.service? x.service.name:'N/A',
          service_id: x.service? x.service.id:'',
          start_time: x.start_time?x.start_time :'N/A',
          end_time: x.end_time?x.end_time :'N/A',
          break_minute:x.break_minutes? x.break_minutes :'-',
          note: x.note,
        }))

        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray
      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

    },
    async removeService(id){
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure to delete this shift template?',
          text: "This action cannot be reverted.",
          imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            await settings.removeShiftTemplate(id)
            this.$refs.table.refresh()
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: '',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            this.$refs.table.refresh()
            this.tableLoading = false
            this.$swal({
              title: 'Cancelled',
              text: ' ',

              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
        //  await settings.removeServiceForPractice(id)
        // this.showSuccessMessage('Remove service Successful')
        // this.$refs.table.refresh()

      }catch (error){
        this.convertAndNotifyError(error)
      }
    }
  },
  mounted () {

  }
}
</script>

<style scoped>

</style>
