<template>
  <div>
  <b-row>

    <b-col cols="12">
      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >
      <b-table
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :items="getUsers"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          hover
          class="mobile_table_css"
          responsive
      >
             <!-- Name $ Avatar -->

        <template #cell(name)="data">
<!--          <b-col>-->
           <ul class="list-inline mb-0">
               <li class="list-inline-item">
                 <b-avatar
                 :src="getUserImage(data.item.id,true)" class="pull-up "
                 />
               </li>
               <li class="list-inline-item"> {{ data.item.name }} </li>

           </ul>
<!--          </b-col>-->
        </template>

             <!-- ResetPassword $ Avatar Lock -->

<!--        <template #cell(redate)="data,">-->
<!--           <ul class="list-inline mb-0">-->
<!--               <li class="list-inline-item">-->
<!--                 -->
<!--                      <b-button  -->
<!--                            size="23"-->
<!--                            variant="outline-light"-->
<!--                            class="btn-icon bg-white text-primary" >-->

<!--                           <feather-icon-->
<!--                              size="18"-->
<!--                              icon="LockIcon" -->
<!--                            />-->
<!--                      </b-button>-->
<!--                </li>-->
<!--               <li class="list-inline-item text-secondary"> {{ data.item.redate }} </li>-->
<!--           </ul>-->
<!--        </template>-->

            <!-- Delete User Button -->

          <template #cell(action)="data">
              <b-dropdown
                   variant="link"
                   toggle-class="text-decoration-none"
                   no-caret
              >
               <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
                <b-dropdown-item>
                  <b-button variant="outline-white" @click="AssignAdminRole(data.item)" >
                    <feather-icon icon="UserCheckIcon" class="mr-50"  />
                    <span>Assign Admin Role</span>
                  </b-button>
                </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="outline-white" @click="confirmButtonColor(data.item)" >
                    <feather-icon icon="TrashIcon" class="mr-50"  />
                    <span>Delete User</span>
                </b-button>
              </b-dropdown-item>

            </b-dropdown>
          </template>

      </b-table>
      </b-overlay>
    </b-col>
  </b-row>
  <div class="mx-1 mb-2">
    <b-row>
            <b-col cols="12" v-if="noDataTable === 0" class="text-center">
              <span >No data for preview</span>
            </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>


    </b-row>
  </div>
  </div>
</template>

<script>
import Settings from '@/apis/modules/settings'
import MomentMixin from '@/mixins/MomentMixin'
import {BDropdown, BDropdownItem, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BOverlay
} from 'bootstrap-vue'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'

export default {
  mixins: [MomentMixin],
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay
  },
  directives: {
    Ripple,
  },
  methods:{
      confirmButtonColor(user) {
      this.$swal({
        title: `Are you sure you want to delete ${user.name}?`,
        text: "This action cannot be reverted.",
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your imaginary file is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

    AssignAdminRole(user) {
        try {
          this.$swal({
            title: `Are you sure you want to assign admin role to ${user.name} ?`,
            text: "This person will have access to all practise information and will be able to manage rota and staff bank.",
            imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
            showCancelButton: true,
            confirmButtonText: 'Yes, assign',
            cancelButtonText: 'No, go back',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-primary ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$swal({
                icon: 'success',
                title: 'Assigned!',
                text: 'Admin Role is Assigned',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } else if (result.dismiss === 'cancel') {
              this.$swal({
                title: 'Cancelled',
                text: '',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }catch(error) {
          this.convertAndNotifyError(error)
        }
    },

    async getUsers() {
        try {
          this.tableLoading = true
          const Response = await Settings.getUsers(this.currentPage,10)

          const dataArray = Response.data.data.map((x) => ({
             id:x.id,
            name:x.user.first_name + ' ' + x.user.last_name ,
            email:x.user.email,
            telephone:x.user.phone_number?x.user.phone_number:'N/A',
            // create_date:x.created_at?x.created_at:'N/A',
            create_date:x.created_at?this.momentFormat(x.created_at,'dddd YYYY-MM-DD'):'N/A',
            // login_date:x.updated_at?x.updated_at: 'N/A'
            // login_date:x.updated_at?this.momentFormat(x.updated_at,'dddd YYYY-MM-DD'):'N/A'
            login_date: this.momentFormat(new Date(), 'ddd DD MM YYYY')
          }))

          this.noDataTable = Response.data.data.length
          const paginationResponse = Response.data.meta
          this.currentPage = paginationResponse.current_page
          this.pagination.totalRows = paginationResponse.total
          this.pagination.perPage = paginationResponse.per_page
          this.pagination.from = paginationResponse.from
          this.pagination.to = paginationResponse.to
          this.tableLoading = false
          return dataArray

        } catch (error) {
          this.convertAndNotifyError(error)
          this.tableLoading = false
        }
    }

  },

  data() {
    return {
      tableLoading:false,
      perPage: 100,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      noDataTable:'',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      fields: [
             {
          key: 'name',
          label: 'NAME',
               // tdClass:'row',
          sortable: true
        },
        {
          key: 'email',
          label: 'EMAIL',
        },
        {
          key: 'telephone',
          label: 'TELEPHONE',
        },

        {
          key: 'create_date',
          label: 'DATE ACCOUNT CREATED',
          sortable: true
        },
        {
          key: 'login_date',
          label: 'LAST LOGIN DATE',
          sortable: true

        },
        {
          key: 'action',
          label: '',
        },

      ],
      // items: [
      //
      //   {
      //     'name': 'Brooklyn Simmons',
      //     'email': 'brooklyn.simons@mp.co.uk',
      //     'telephone': '0712345678',
      //     'create_date': '05/03/2021',
      //     'login_date': '05/03/2021',
      //
      //   },
      //   {
      //     'name': 'Cameron Williamson',
      //     'email': 'cameron.williamson@mp.co.uk',
      //     'telephone': '0712345678',
      //     'create_date': '17/02/2021',
      //     'login_date': '23/02/2021',
      //
      //   },
      //     {
      //     'name': 'Courtney Henry',
      //     'email': 'courtney.henry@mp.co.uk',
      //       'telephone': '0712345678',
      //       'create_date': '12/02/2021',
      //     'login_date': '12/02/2021',
      //
      //   },
      //     {
      //     'name': 'Jenny Wilson',
      //     'email': 'jenny.wilson@mp.co.uk',
      //       'telephone': '0712345678',
      //       'create_date': '30/01/2021',
      //     'login_date': '19/02/2021',
      //
      //   },
      //     {
      //     'name': 'Arlene McCoy',
      //     'email': 'arlene.mccoy@mp.co.uk',
      //       'telephone': '0712345678',
      //       'create_date': '30/01/2021',
      //     'login_date': '30/01/2021',
      //
      //   },
      //     {
      //     'name': 'Theresa Webb',
      //     'email': 'theresa.webb@mp.co.uk',
      //       'telephone': '0712345678',
      //       'create_date': '25/01/2021',
      //     'login_date': '12/02/2021',
      //
      //   },
      // ]
    }
  },

 async mounted() {
   await this.getUsers()
  },

}
</script>
<style>
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}

/*.row {*/
/*  max-width: 300px;*/
/*}*/
</style>
