<template>
  <div>
    <div>
      <div>
        <b-form>
          <b-row class="settings-block bg-light-blue mb-1 py-1">
            <b-col md="3" lg="7">
              <div class="font-weight-bold ml-2">
                <p>ROLE</p>
              </div>
            </b-col>

            <b-col md="9" lg="5">
              <div class="font-weight-bold txt-dark">
                <b-row>
                  <b-col md="1" />
                  <b-col md="3"><p>STANDARD RATE P/H</p></b-col>
                  <b-col md="3"><p>ENHANCED RATE P/H</p></b-col>
                  <b-col md="3"><p>LOCUM RATE P/H</p></b-col>
                  <b-col md="1" />
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-form>
<!-- {{items.length}}-->
        <b-overlay
            :show="formLoading"
            rounded="sm"
        >
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <validation-observer
              ref="CreateJobRoleForm"
          >
          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <!-- Item Name -->

            <b-col md="3" lg="7" class="align-self-center">
              <div class="ml-2 set-mb">
                <div class="set-mb-head d-md-none">ROLE</div>
                <h6>{{item.role}}</h6>
                </div>
            </b-col>

            <!-- Standard / Enchanced /Locum X Button-->
            <b-col md="9" lg="5">
              <b-row>
                <b-col md="1" />
                <b-col md="3">
                  <div class="set-mb">
                    <div class="set-mb-head d-md-none">STANDARD PATH P/H</div>
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          name="Standard path p/h"
                          rules="numeric"
                      >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          £
                        </b-input-group-prepend>
                    <b-form-input
                    id="standard"
                    placeholder="0.00"
                    v-model="item.standard_rate_p_h"
                  />
                      </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                </b-col>
                <b-col md="3">

                  <div class="set-mb">
                  <div class="set-mb-head d-md-none">ENHANCED RATE P/H</div>
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          name="Enhanced Rate p/h"
                          rules="numeric"
                      >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          £
                        </b-input-group-prepend>
                  <b-form-input
                    id="enchanced"
                    placeholder="0.00"
                    v-model="item.enhanced_rate_p_h"
                  />
                      </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                </b-col>
                <b-col md="3">
                  <div class="set-mb">
                  <div class="set-mb-head d-md-none">LOCUM RATE P/H</div>
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          name="Locum Rate p/h"
                          rules="numeric"
                      >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          £
                        </b-input-group-prepend>
                  <b-form-input id="locume" placeholder="0.00" v-model="item.locum_rate_p_h" />
                      </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                </b-col>
                <b-col md="1">
                  <b-button
                    size="23"
                    variant="danger"
                    class="btn-icon"
                    @click="removeItem(index,item.job_role_id)"
                    v-b-tooltip.hover.top="'Delete Role'"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12"> <hr /> </b-col>


          </b-row>
          </validation-observer>
        </b-form>
        </b-overlay>
      </div>

      <b-row>
        <!-- Add Button -->
        <b-col md="3" lg="7">
          <div class="set-mb">
            <div class="set-mb-head d-md-none">ROLE</div>

          <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            plain
            style="background-color: rgba(255, 225, 225, 0.1)"
            drop text="+ Add Role "
            variant=" bg-white text-primary"

          >
            <b-form-group class="mx-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="icons-search"
                  v-model="filters['name']"
                  placeholder="Search"
                />
              </b-input-group>
            </b-form-group>

            <div class="ml-1 mr-1 scrollable-menu" @click="repeateAgain">

              <b-dropdown-item
                  v-for="option in rolesOption"
                  :key="option.id"
                  :value="option.name"
                  @click="selectedRole=option"
              > {{option.name}}
              </b-dropdown-item>
<!--              <b-dropdown-item>Health and wellbeing coach</b-dropdown-item>-->
<!--              <b-dropdown-item>Care coordinator</b-dropdown-item>-->
<!--              <b-dropdown-item>Physician associate</b-dropdown-item>-->
<!--              <b-dropdown-item>Dietician</b-dropdown-item> -->
            </div>
          </b-dropdown>
          </div>

        </b-col>

<!--        <b-col md="9" lg="5">-->

<!--          <b-row>-->
<!--            <b-col md="1" />-->
<!--            <b-col md="3">-->
<!--              <div class="set-mb">-->
<!--              <div class="set-mb-head d-md-none">STANDARD PATH P/H</div>-->
<!--              <b-form-input id="standard" type="number" placeholder="£0.00" />-->
<!--              </div>-->

<!--            </b-col>-->
<!--            <b-col md="3">-->
<!--              <div class="set-mb">-->
<!--              <div class="set-mb-head d-md-none">ENCHANCED RATE P/H</div>-->
<!--              <b-form-input id="enchanced" type="number" placeholder="£0.00" />-->
<!--              </div>-->

<!--            </b-col>-->
<!--            <b-col md="3">-->
<!--              <div class="set-mb">-->
<!--              <div class="set-mb-head d-md-none">LOCUM RATE P/H</div>-->
<!--              <b-form-input id="locume" type="number" placeholder="£0.00" />-->
<!--              </div>-->

<!--            </b-col>-->
<!--            <b-col md="1" />-->
<!--          </b-row>-->

<!--        </b-col>-->
        <b-col><hr /></b-col>
      </b-row>

      <div class="d-flex ml-1 mt-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
         :disabled="isActive"
          variant="primary"
          @click="updateRoles()"
        >
          Save
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="text-primary ml-1 shadow"
          type="button"
          variant="outline-white"
          @click = "cancelRecords()"
        >
          Cancel
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BPagination,
  BRow,
  BTable,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import {heightTransition} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import settings from '@/apis/modules/settings'
import Filter from '@/mixins/FilterMixin'
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BBadge,
    BCard,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BTable,
    BOverlay,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [Filter,heightTransition],
  data() {
    return {
      formLoading:false,
      rolesOption:[],
      selectedRole:'',
      selectedRoleId:null,
      items: [
        // {
        //   id: 1,
        //   selected: "male",
        //   selected1: "designer",
        //   prevHeight: 0,
        // },
      ],
      nextTodoId: 1,
      filters: {},
      filter: null,
      filterOn: [],
      temperaryRecords:[],
      isActive: true,
    };
  },
  mounted() {
    this.initTrHeight();
    this.addRole();
    this.getJobRoleList();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },

  watch: {
    selectedRole () {
      this.isActive = false
    },

    items() {
      this.items.forEach((val) => {
        this.$watch(() => val, this.handleChange, {deep: true})
      })
    }
  },

  methods: {
    handleChange() {
      this.isActive = false
    },


repeateAgain () {

       if(this.items.length !== 0){ //products array
       let array_e = this.items;  //products array

  array_e.forEach((element) => { 
    var obj = _.find(array_e, (find) => find.job_role_id == this.selectedRole.id); 
    if(!obj ){
       this.id = true; 
    }
    else{
      this.id = false; 
    }
  });
       }
       else{
        this.id = true; 
       }
   if( this.id === true){
      try {
        this.items.push({
          id: 'new_' + this.nextTodoId,
          job_role_id: this.selectedRole.id,
          role: this.selectedRole.name,
          standard_rate_p_h: '',
          enhanced_rate_p_h: '',
          locum_rate_p_h: ''
        })
        this.nextTodoId++

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        })
      } catch (error) {
        this.convertAndNotifyError(error)
      }
   }
    },

  
    removeItem(index,jobRoleId) {
      try {
        this.formLoading = true
        this.$swal({
          title: 'Are you sure to delete this role?',
          text: "This action cannot be reverted.",
          imageUrl: require('@/assets/images/icons/publish.png'),
            imageWidth: 80,
            imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.items.splice(index, 1);
            this.trTrimHeight(this.$refs.row[0].offsetHeight);
            await settings.deleteJobRole(jobRoleId)
            // this.trTrimHeight(this.$refs.row[0].offsetHeight);
            await this.addRole()

            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Your role has been deleted.',

              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.formLoading = false
            this.$refs.form.refresh()

          } else if (result.dismiss === 'cancel') {
            this.formLoading = false
            this.$swal({
              title: 'Cancelled',
              text: ' ',

              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      }catch (error){
        this.convertAndNotifyError(error)
      }
      this.formLoading = false
      // this.items.splice(index, 1);
      // const Response = settings.deleteJobRole(jobRoleId)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },

    async addRole(){
      try {
        // this.showAddRole = true
        const notAssignee = await settings.jobRoleNotAssignerList(this.filterQuery)
        this.rolesOption =notAssignee.data.data.map((x) => ({
          id:x.id,
          name: x.name,
        }))
        // console.log('dropdown',this.rolesOption)
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },

    filterQueryUpdate () {
      this.addRole()
    },

    cancelRecords() {
      this.trTrimHeight(this.$refs.row[0].offsetHeight * this.items.length);
       this.getJobRoleList()
    },

    async updateRoles() {
      if (await this.$refs.CreateJobRoleForm.validate()) {
        try {
          this.formLoading = true
          let formData = new FormData;
          formData.append("items", JSON.stringify(this.items))
          // console.log(formData)
          const Response = await settings.updateRoles(formData)
          // console.log('roles',Response)
          this.showSuccessMessage('Role updated successfully')
          await this.addRole()
          this.formLoading = false
          this.isActive = true

        } catch (error) {
          this.convertAndNotifyError(error)
          this.formLoading = false
        }
      }
    },

    async getJobRoleList () {
      try {
        this.formLoading = true
        const Response = await settings.assignedJobRoleList()
        this.items = Response.data.data.map((x) => ({
              id: x.id,
              job_role_id: x.pivot.job_role_id,
              role: x.name,
              standard_rate_p_h: x.pivot.standard_rate_p_h,
              enhanced_rate_p_h: x.pivot.enhanced_rate_p_h,
              locum_rate_p_h: x.pivot.locum_rate_p_h
            })
        )
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight * this.items.length)
        })
        this.formLoading = false

      }catch(error) {
        this.convertAndNotifyError(error)
        this.formLoading = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.scrollable-menu {
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
