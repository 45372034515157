<template>
  <div>
    <b-row>


      <b-col cols="12">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
          <b-table
              ref="table"
              :current-page="currentPage"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :items="getInvitations"
              :per-page="pagination.perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              class="mobile_table_css"
              hover
              responsive
          >

            <template v-slot:head(send_date)="data">
              <span v-b-tooltip.hover.top="'Sort'">{{ data.label }}</span>
            </template>

            <template #cell(name)="data">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <b-avatar
                      :src="getUserImage(data.item.id,true)" class="pull-up "
                  />
                </li>
                <li class="list-inline-item"> {{ data.item.name }} </li>

              </ul>
            </template>


            <template #cell(action)="data">
              <div>
                <b-badge v-if="data.item.state ==='Accepted'" class="ml-1" style="padding:8px" variant="light-info">

                </b-badge>

                <b-button
                    v-b-tooltip.hover.top="'Remove'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn-icon"
                    variant="danger"
                    @click="deleteInvitation(data.item.id)"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </div>

            </template>


          </b-table>
        </b-overlay>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span v-if="pagination.totalRows !==0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>
    </b-row>


  </div>

</template>

<script>

import {BCard, BTabs, BTab, BButton, BDropdown, BDropdownItem, VBTooltip} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import vSelect from 'vue-select'


import {

  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BAlert,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormInvalidFeedback,
  BSidebar,
  BFormTextarea,
  VBModal,
  BOverlay
} from 'bootstrap-vue'
import settings from '@/apis/modules/settings'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'


export default {
  name: 'users-invitations',
  components: {
    vSelect,
    BInputGroupPrepend,
    BCard,
    BButton,
    BTabs,
    BTab,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  data() {
    return {
      tableLoading: false,
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'email',
          label: 'EMAIL'
        },
        {
          key: 'action',
          label: 'Action'
        }

      ],
      items: [],

    }
  },
  methods: {


    async deleteInvitation(id) {
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure to remove the admin?',
          text: "This action cannot be reverted.",
          imageUrl: require('@/assets/images/icons/publish.png'),
            imageWidth: 80,
            imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, remove',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const response = await settings.removeAdmin(id)
            this.$refs.table.refresh()
            if (response.data.data.success) {
              this.showSuccessMessage(response.data.data.text)
            } else {
              this.showErrorMessage(response.data.data.text)
            }

          } else if (result.dismiss === 'cancel') {
            this.$refs.table.refresh()
            this.tableLoading = false
            this.$swal({
              title: 'Cancelled',
              text: ' ',

              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
        // await settings.deleteInvitation( id)
        // this.showSuccessMessage('Delete Invitation Successful')
        // this.$refs.table.refresh()
        // this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },

    async getInvitations() {
      try {
        this.tableLoading = true
        const Response = await settings.getAdmins(this.currentPage, 10)
        const dataArray = Response.data.data.map((x) => ({
          id: x.user.id,
          email: x.user.email,
          name: x.user.first_name + ' ' + x.user.last_name,
        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        return dataArray
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length


  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
