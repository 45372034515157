<template>
  <b-row>
    <b-col cols="12">
        <b-table
            :fields="fields"
            :filter-included-fields="filterOn"
            :items="items"
            class="mobile_table_css"
        >
            <template #cell(msg)="data">

                <ul class="list-inline mb-0 pt-1">
                <li class="list-inline-item">
                    <b-form-checkbox v-model="isTimesheetEnable" name="check-button" switch  />
                </li>
                <li class="list-inline-item text-secondary"> {{ data.item.msg }} </li>
            </ul>
            </template>
        </b-table>
      <hr>
    </b-col>

    <div class="d-flex ml-1">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            :disabled="isSaveButtonDisabled"
            @click="saveEnableState()"
        >
            Save
        </b-button>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="text-primary ml-1 shadow"
            type="button"
            variant="outline-white"
            :disabled="isSaveButtonDisabled"
            @click="cancelEnableButton()"
        >
            Cancel
        </b-button>
    </div>

  </b-row>
</template>

<script>

import {BFormCheckbox, BDropdown, BDropdownItem, BIconToggleOn } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TimesheetAPI from '@/apis/modules/timesheet'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BIconToggleOn,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      filter: null,
      isTimesheetEnable: false,
      respondvalue: false,
      isSaveButtonDisabled:true,
      filterOn: [],
      fields: [
        {
          key: 'msg',
          label: 'Timesheets',
        },
      ],
      items: [

        {
          'msg': 'Enable timesheets',
        },

      ]
    }
  },
  methods:{
    async getEnableState(){
      try{
        const Response = (await TimesheetAPI.getTimesheetEnableState()).data;
        this.isTimesheetEnable = !! Response.is_enable_time_sheet
        this.respondvalue = Response.is_enable_time_sheet

      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    async saveEnableState(){
      this.isSaveButtonDisabled = true
      try{
        if(this.respondvalue){
          this.$swal({
            title: 'Are you sure you want to disable timesheet?',
            text: '',
            imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
            showCancelButton: true,
            confirmButtonText: 'Yes, disable',
            cancelButtonText: 'No, go back',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async result => {
            if (result.value) {
              await TimesheetAPI.enableTimesheet()
              this.showSuccessMessage('Timesheet disabled successfully')
              await this.getEnableState();
            } else {
              this.isTimesheetEnable = true
            }
          })
        }else{
           this.$swal({
            title: 'Are you sure you want to enable timesheet?',
            text: '',
            imageUrl: require('@/assets/images/icons/publish.png'),
            imageWidth: 80,
            imageHeight: 80,
            showCancelButton: true,
            confirmButtonText: 'Yes, enable',
            cancelButtonText: 'No, go back',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async result => {
            if (result.value) {
              await TimesheetAPI.enableTimesheet()
              this.showSuccessMessage('Timesheet enabled successfully')
              await this.getEnableState();
            } else {
              this.isTimesheetEnable = false
            }
          })
        }

      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    cancelEnableButton(){
      this.isTimesheetEnable = !this.isTimesheetEnable
      this.isSaveButtonDisabled = true
    }
  },
  watch:{
    isTimesheetEnable(val){
      if(val != this.respondvalue){
        this.isSaveButtonDisabled = false
      }
      else{
        this.isSaveButtonDisabled = true
      }
    }
  },
  async mounted(){
    setTimeout(() => {
      this.mounted = true
    }, 1000)
    await this.getEnableState();
  }

}
</script>
<style>
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}
</style>
